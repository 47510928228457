import React from "react";
import { useLocation } from "react-router-dom";
import FullPage from "../../components/FullPage";
import Descontos from "./Section/Descontos";
import SViaBoleto from "./Section/SViaBoleto";
import BemVindo from "../../components/BemVindo";
import faturasInadimplentes from "./Section/FaturasInadimplentes";
import NegociacoesAceitas from "./Components/NegociacoesAceitas";
import NegociacoesFaturas from "./Components/NegociacoesFaturas";

const FinanceiroPage = (props) => {
  const location = useLocation();
  let path = location.pathname

  let config = {
    parentUrl: path,
    parentName: "FINANCEIRO",
    parentComponent: BemVindo,
    routes: [
      {
        route: "faturas",
        text: "FATURAS",
        component: SViaBoleto,
      },
      {
        route: "negociar",
        text: "NEGOCIAR FATURAS ATRASADAS",
        component: NegociacoesFaturas,
      },
      {
        route: "negociacoes",
        text: "MINHAS NEGOCIAÇÕES",
        component: NegociacoesAceitas,
      },
      {
        route: "descontos",
        text: "DESCONTOS CONCEDIDOS",
        component: Descontos,
      },
      {
        route: "faturas-inadimplentes",
        text: "FATURAS INADIMPLENTES",
        show: "repre",
        component: faturasInadimplentes,
        user: "repre",
      },
    ],
  };

  return <FullPage {...config} />;
};

export default FinanceiroPage;
