import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Modal from '../../../components/Modal/Modal';
import ConverteDinheiroFunction from '../../../Utils/ConverteDinheiroFunction';
import DetailsItem from '../../../components/DetailsBox/DetailsBoxItem';
import ConverteDataFunction from '../../../Utils/ConverteDataFunction';
import { useState } from 'react';

const NegociacoesModal = ({ data, modals, setModals, sendNegotiation }) => {
    const {
        valorInicial,
        valorFinal,
        valorParcela,
        entrada,
        numeroParcelas,
        formaPagamento,
        boleto,
        faturas
    } = data

    const navigate = useNavigate();
    const [showFaturas, setShowFaturas] = useState(false)

    const widthScreen = () => {
        if (window.innerWidth < 481) return { widthCss: '100%', qtdColumns: 2 }
        else if (window.innerWidth < 769) return { widthCss: '80%', qtdColumns: 2 }
        else return { widthCss: '50%', qtdColumns: 4 }
    }
    const { qtdColumns, widthCss } = widthScreen()
    const blocosFaturas = faturas?.reduce((acc, curr, index) => {
        const blockIndex = Math.floor(index / qtdColumns) + 1; // Calcule o índice do bloco
        const blockKey = `block${blockIndex + 4}`;//nomes começarem com "block5"

        const newBlock = [
            {
                key: 'Fatura: ',
                value: `${curr.fatura.trim()}-${curr.id_parcela.trim()}`,
                componentProps: { style: { fontSize: '0.6875rem', lineHeight: 1.3 } }
            },
            {
                key: 'Valor: ',
                value: ConverteDinheiroFunction(curr.saldo_principal_devido_com_juros),
                componentProps: { style: { fontSize: '0.6875rem', lineHeight: 1.3 } }
            },
            {
                key: 'Venc.: ',
                value: ConverteDataFunction(curr.vencimento_real),
                componentProps: { style: { fontSize: '0.6875rem', lineHeight: 1.3, } }
            }
        ]

        // Se o bloco ainda não existe, inicialize-o
        if (!acc[blockKey]) {
            acc[blockKey] = [];
        }

        // Adiciona o elemento atual em um array e insere no bloco correspondente
        if (acc[blockKey].length < qtdColumns) {
            acc[blockKey].push(newBlock);
        } else {
            acc[`block${blockIndex + 1}`] = [newBlock];
        }

        return acc;
    }, {});

    const cardInfo = {
        componentProps: { additionalClassName: 'itemInfo_gray' },
        title: {
            id: ``,
            description: <span style={{ color: '#D66157' }}>
                Valor inicial: {ConverteDinheiroFunction(valorInicial)}
            </span>,
            key: '',
        },
        footer: {
            id: ``,
            description: <span style={{ color: '#196621' }}>
                Valor total a pagar: {ConverteDinheiroFunction(valorFinal + (entrada || 0))}
            </span>,
            key: '',
        },
        blocksInfos: {
            block1: [
                [{ key: 'Forma de pagamento: ', value: formaPagamento }],
            ],
            block2: [
                [{ key: 'Entrada: ', value: ConverteDinheiroFunction(entrada) || '-' }],
            ],
            block3: [
                [{ key: 'Nº de parcelas: ', value: numeroParcelas || '-' },],
                [{ key: 'Valor: ', value: ConverteDinheiroFunction(valorParcela) || '-' }],
                [{ key: 'Intervalo: ', value: boleto || '-' }],
            ],
            block4: [[{
                key: 'Faturas Selecionadas: ',
                value:
                    <span
                        style={{ cursor: 'pointer', color: ' #AD9078', textDecoration: 'underline' }}
                        onClick={() => setShowFaturas(prev => !prev)}>
                        {showFaturas ? 'esconder' : 'mostrar'}
                    </span>

            }]],
            ...(showFaturas ? blocosFaturas : {})
        },
    }

    const modalConfirmarNegociacao = {
        modalName: "ACEITAR NEGOCIAÇÃO",
        closeName: "Voltar",
        onClose: () => setModals(prev => ({ ...prev, confirmar: false })),
        actionName: "FINALIZAR NEGOCIAÇÃO",
        open: modals.confirmar,
        action: async () => {
            try {
                await sendNegotiation(true)
                setModals({ confirmar: false, finalizar: true })
            } catch (error) {
                toast.error('Algo de errado aconteceu, tente novamente ou entre em contato!')
            }
        },
        style: { width: widthCss }
    }

    const modalFinalizarNegociacao = {
        open: modals.finalizar,
        modalName: "NEGOCIAÇÃO ENVIADA",
        actionName: "SAIR",
        action: () => { navigate('/Financeiro/faturas') },
    }

    return (
        <>
            <Modal {...modalConfirmarNegociacao}>
                <p>Após confirmar a escolha da negociação com os parâmetros abaixo, não será possível fazer nenhuma alteração.</p>
                <DetailsItem {...cardInfo} />
            </Modal>

            <Modal {...modalFinalizarNegociacao}>
                <p>Sua negociação já está com o time financeiro. Em breve você receberá instruções para os próximos passos.</p>
            </Modal>
        </>
    )
}

export default NegociacoesModal