import React, { useState, useEffect } from "react";
import { authFetch } from '../../../helpers/authHelper';

import SomaDataOverview from "../../../components/SomaStandard/SomaDataOverview/SomaDataOverview";
import SomaAutocomplete from "../../../components/SomaStandard/SomaAutocomplete/SomaAutocomplete";
import SomaLoader from "../../../components/SomaStandard/SomaLoader/SomaLoader";

import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useNavigate } from "react-router-dom";

import "./Styles.css";
import useUser from "../../../Hooks/UserContext";

const VisaoInadimplencia = (props) => {
    const navigate = useNavigate();
    const user = useUser();

    const [data, setData] = useState();
    const [dataOutrasMarcas, setDataOutrasMarcas] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [loadingClients, setLoadingClients] = useState(true);
    const [loadingData, setLoadingData] = useState(true)
    const [selectedClientes, setSelectedClientes] = useState([{ COD_CLIFOR: 'TODOS', NOME_CLIFOR: '' }])
    const [lastUpdated, setLastUpdated] = useState();
    const [mounted, setMounted] = useState(false);

    const formatarDadosVisao = (result) => {
        return [
            {
                title: props.isRepre ? 'Valor a receber' : 'Valor a pagar',
                subtitle: 'Total',
                value: result.valor_receber
            },
            {
                title: 'Valor vencido',
                subtitle: 'Total',
                value: result.valor_vencido,
                color: "#D66157",
                isToNegotiate: user.features?.admin,//result.valor_vencido > 0,
                button: {
                    handle: () => { navigate('/Financeiro/negociacoes') },
                    name: 'VER NEGOCIAÇÃO'
                }


            },
            {
                title: 'VALOR VENCENDO HOJE',
                subtitle: 'Total',
                value: result.valor_vencendo_hoje,
                color: "#E3CC6C"
            }
        ];
    }

    const formatarDataAlteracao = (dataUltimaAlteracao) => {
        let data = new Date(dataUltimaAlteracao);

        let horas = ("0" + data.getHours()).slice(-2);
        let minutos = ("0" + data.getMinutes()).slice(-2);
        let dia = ("0" + data.getDate()).slice(-2);
        let mes = ("0" + (data.getMonth() + 1)).slice(-2);
        let ano = data.getFullYear();

        return `Última atualização foi feita às ${horas}h${minutos} de ${dia}/${mes}/${ano}`;
    }

    const getClientes = async () => {
        try {
            const clientsResponse = await (await authFetch("/v1/boleto/clientes")).json();
            setClientes([{ COD_CLIFOR: 'TODOS', NOME_CLIFOR: '' }, ...clientsResponse]);
            setLoadingClients(false);
        } catch (err) {
            setClientes([]);
            setLoadingClients(false);
        }
    };

    const getVisaoInadimplencia = async () => {
        setLoadingData(true);

        try {
            const result = await (await authFetch(`/v1/boleto/info`, "POST", selectedClientes)).json();
            setLastUpdated(result.updated_at);

            const data = formatarDadosVisao(result)

            if (result.outras_marcas && Object.keys(result.outras_marcas).length !== 0) {
                const dataOutras = formatarDadosVisao(result.outras_marcas)
                setDataOutrasMarcas(dataOutras);
            } else {
                setDataOutrasMarcas([]);
            }

            let dataUltimaAlteracao = formatarDataAlteracao(result.updated_at);
            setLastUpdated(dataUltimaAlteracao);

            setData(data);
            setLoadingData(false);
        } catch (err) {
            setDataOutrasMarcas([]);
            setData([]);
            setLoadingData(false);
        }
    };

    useEffect(() => {
        if (mounted) {
            props.setClients(selectedClientes);
        } else {
            setMounted(true);
            getClientes();
        }
        getVisaoInadimplencia();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedClientes]);

    const handleUpdate = async () => {
        try {
            props.setClients([{ COD_CLIFOR: 'TODOS', NOME_CLIFOR: '' }]);
            props.setIsUpdating(true);

            const result = await (await authFetch(`/v1/boleto/update`, "POST")).json();

            const data = formatarDadosVisao(result);
            setData(data);

            let dataUltimaAlteracao = formatarDataAlteracao(result.updated_at);
            setLastUpdated(dataUltimaAlteracao);

            props.setIsUpdating(false);
        }
        catch (err) {
            props.setIsUpdating(false)
            console.log(err);
            setData([]);
            setLoadingData(false);
        }
    }

    return (
        <>
            {(loadingClients || loadingData) ? (
                <SomaLoader loader="spinner" />
            ) : (
                <div>
                    {props.isRepre && <div>
                        <SomaAutocomplete
                            options={clientes}
                            selected={selectedClientes}
                            setSelected={setSelectedClientes}
                        />
                        <div className="div__visaoInadimplencia--update">
                            <button className="div__visaoInadimplencia--button">
                                <AutorenewIcon onClick={() => handleUpdate()} color="secondary" />
                            </button>
                            <p className="div__visaoInadimplencia--lastUpdated">{lastUpdated}</p>
                        </div>
                    </div>
                    }
                    <SomaDataOverview data={data} title={props.isRepre ? "MINHAS MARCAS" : ""} />
                    {dataOutrasMarcas.length !== 0 ? <SomaDataOverview data={dataOutrasMarcas} title={"OUTRAS MARCAS"} /> : <></>}
                </div>
            )}
        </>
    );
};

export default VisaoInadimplencia;
