import React, { useEffect, useRef, useState } from 'react';

import { Button } from '@mui/material';
import { useBeforeUnload, useNavigate } from 'react-router-dom';

import CardBox from '../../../components/CardBox/Card';

import SelectNegotiation from './SelectNegotiation/SelectNegotiation';
import { authFetch } from '../../../helpers/authHelper';
import SomaStatus from '../../../components/SomaStandard/SomaStatus';
import ConverteDinheiroFunction from '../../../Utils/ConverteDinheiroFunction';
import ConverteDataFunction from '../../../Utils/ConverteDataFunction';

import TitlesSelector from '../../../components/TitlesSelector/TitlesSelector';
import InputRange from '../../../components/InputRange/InputRange';
import NegociacoesModal from './NegocicoesModal';

const NegociacoesFaturas = () => {
    const navigate = useNavigate();

    const [modals, setModals] = useState({
        confirmar: false,
        finalizar: false,
    });

    const [faturas, setFaturas] = useState({
        selecionadas: [],
        totalSelecionadas: undefined,
        valorParcela: undefined,
    });

    const [pagamento, setPagamento] = useState({
        forma: undefined,
        numParcelas: undefined,
        entrada: undefined,
        boletos: undefined
    });

    const [status, setStatus] = useState('wait');
    const ref = useRef([])
    const [results, setResults] = useState([])

    const calcValorlEntrada = (valor, perc) => {
        return (perc / 100) * valor
    }

    const calcTaxaAdm = (valor, numParcelas, juros) => {
        const taxaPorParcela = Math.pow((1 + (juros / 100)), numParcelas)
        return valor * taxaPorParcela
    };

    const clickCheckbox = (selectedItems, formaPagamento, boletos) => {
        const percInicialEntrada = 10
        formaPagamento = formaPagamento || pagamento.forma
        boletos = boletos || pagamento.boletos

        const newFaturasSelecionadas = results?.faturas
            .filter(item => selectedItems.includes(`${item.fatura.trim()}${item.id_parcela.trim()}`))

        const newTotal = newFaturasSelecionadas.reduce((acc, curr) => acc + curr.saldo_principal_devido_com_juros, 0);

        let qtdParcelas
        let juros
        let entrada = 0
        if (formaPagamento === 'cartao') {
            qtdParcelas = 12
            juros = results?.juros?.admCartao
        }
        else if (formaPagamento === 'boleto') {
            if (boletos === 'semanal') qtdParcelas = 12
            else if (boletos === 'quinzenal') qtdParcelas = 8
            else if (boletos === 'mensal') qtdParcelas = 4

            juros = results?.juros?.admBoleto
            entrada = pagamento?.entrada?.valor;
        }

        let formaPgto = {}
        let totalFormaPgto = {}

        for (let parcelas = 1; parcelas <= qtdParcelas; parcelas++) {
            const newValorParcela = calcTaxaAdm((newTotal - entrada) / parcelas, parcelas, juros);
            formaPgto[`${parcelas}`] = newValorParcela
            totalFormaPgto[`${parcelas}`] = newValorParcela * parcelas
        }

        const pix = { '0': newTotal }

        setFaturas({
            selecionadas: newFaturasSelecionadas,
            totalSelecionadas: { formaPgto: totalFormaPgto, pix },
            valorParcela: { formaPgto, pix }
        })
        setPagamento(prev => ({
            ...prev,
            forma: formaPagamento,
            boletos: formaPagamento === 'boleto' ? boletos : undefined,
            numParcelas: undefined,
            entrada: formaPagamento === 'boleto' ?
                {
                    valor: calcValorlEntrada(newTotal, prev.entrada?.perc || percInicialEntrada),
                    perc: prev.entrada?.perc || percInicialEntrada
                }
                : undefined

        }))
    }

    const sendNegotiation = async (finalizado) => await authFetch(`/v1/negociacao/proposta`, 'post', { ...ref.current, finalizado })

    const propsCardBox = {
        title: {
            name: 'Valores a pagar',
        },
        infos: [
            { left: 'Dívida total', right: ConverteDinheiroFunction(results?.divida?.total) },
            { left: 'Dívida total atualizada', right: ConverteDinheiroFunction(results?.divida?.totalComJuros) },
            { left: 'Dívida cobrança externa', right: ConverteDinheiroFunction(results?.divida?.externa) },
        ],
        infoHighlighted: {
            left: 'Dívida cobrança interna atualizada',
            right: ConverteDinheiroFunction(results?.divida?.internaComJuros)
        }
    }

    const propsTitlesSelector = {
        total: faturas.totalSelecionadas?.pix?.['0'],
        clickCheckbox: clickCheckbox,
        faturas: results?.faturas?.map(f => {
            return {
                idFormated: <>Fatura: {f.fatura.trim()}-{f.id_parcela.trim()}</>,
                valueFormated: <>
                    <br />Valor: {ConverteDinheiroFunction(f.saldo_principal_devido_com_juros)}<br />
                    Venc.: {ConverteDataFunction(f.vencimento_real)}
                </>,
                value: f.saldo_principal_devido_com_juros,
                id: `${f.fatura.trim()}${f.id_parcela.trim()}`
            }
        }),
        resume: { title: 'Valor Selecionado', value: ConverteDinheiroFunction(faturas.totalSelecionadas?.pix?.['0']) }

    }

    const propsFormaPagamento = {
        handleChange: (e) => {
            clickCheckbox(
                faturas.selecionadas.map(f => `${f.fatura.trim()}${f.id_parcela.trim()}`),
                e.target.value,
            )

        },
        options: [
            { value: 'pix', option: 'Pix' },
            { value: 'cartao', option: 'Cartão de Crédito' },
            { value: 'boleto', option: 'Boleto Bancário' },
        ],
        value: pagamento.forma
    }

    const propsOpcoesParcelamento = () => ({
        handleChange: (e) => setPagamento(prev => ({ ...prev, numParcelas: parseInt(e.target.value) })),
        value: pagamento.numParcelas || '',
        options: Object.entries(faturas.valorParcela?.formaPgto).map(([key, value]) => {
            return {
                value: key,
                option: `${key} de ${ConverteDinheiroFunction(value)} - Total de ${ConverteDinheiroFunction(faturas.totalSelecionadas?.formaPgto[key])}`
            }
        })
    })

    const inputRangeProps = {
        range: {
            min: 10,
            max: 100,
            value: pagamento?.entrada?.perc,
            minFormated: `${10}%`,
            maxFormated: `${100}%`
        },
        value: `${ConverteDinheiroFunction(pagamento?.entrada?.valor)} 
        (${pagamento?.entrada?.perc}%)`,
        handleChange: (percentage) => {
            const p = parseInt(percentage)
            const newEntrada = calcValorlEntrada(faturas.totalSelecionadas?.pix['0'], p)
            setPagamento(prev => ({
                ...prev,
                entrada: { valor: newEntrada, perc: p },
                boletos: undefined
            }))
        },
    }


    const propsIntervaloBoletos = {
        handleChange: (e) => {
            clickCheckbox(
                faturas.selecionadas.map(f => `${f.fatura.trim()}${f.id_parcela.trim()}`),
                'boleto',
                e.target.value,
            )

        },
        options: [
            { value: 'semanal', option: 'Semanal - até 12 boletos' },
            { value: 'quinzenal', option: 'Quinzenal - até 8 boletos' },
            { value: 'mensal', option: 'Mensal - até 4 boletos' },
        ],
        value: pagamento.boletos
    }

    useEffect(() => {
        setStatus('wait');
        (async () => {
            try {
                const json = await (await authFetch(`/v1/negociacao/`)).json();
                setResults(json);

                setFaturas({
                    selecionadas: json.faturas,
                    totalSelecionadas: { pix: { '0': json.divida.internaComJuros } },
                    valorParcela: { pix: { '0': json.divida.internaComJuros } }
                })

                setStatus(json.faturas?.length ? 'result' : 'empty');

            } catch (err) {
                setResults(undefined);
                setStatus('error');
            }
        })();
        return () => {
            if (ref.current.formaPagamento && !ref.current.modalFinalizar)
                sendNegotiation()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useBeforeUnload((e) => {
        if (!pagamento.forma) return

        e.preventDefault()
        sendNegotiation()
    });

    useEffect(() => {
        ref.current = {
            faturas: faturas.selecionadas,
            valorInicial: faturas.totalSelecionadas?.pix?.['0'],
            valorFinal: pagamento.forma === 'pix' ?
                faturas.totalSelecionadas?.pix?.['0'] :
                faturas.totalSelecionadas?.formaPgto?.[pagamento.numParcelas],
            formaPagamento: pagamento.forma,
            valorParcela: pagamento.forma === 'pix' ?
                undefined :
                faturas.valorParcela?.formaPgto?.[pagamento.numParcelas],
            numeroParcelas: pagamento.numParcelas,
            entrada: pagamento.entrada?.valor,
            boleto: pagamento.boletos,
            juros: results.juros,
            modalFinalizar: modals.finalizar,
            taxaAdm: pagamento.forma === 'pix' ? undefined :
                pagamento.forma === 'cartao' ?
                    results.juros?.admCartao : results.juros?.admBoleto,
        }
    }, [pagamento,
        faturas,
        results.juros,
        modals.finalizar]); // 

    const formaPagamentoCode = (
        <>
            <p style={{ color: "#757575" }}>2. Escolha a forma de pagamento:</p>
            <SelectNegotiation {...propsFormaPagamento} />
            {
                pagamento.forma === 'cartao' &&
                <>
                    <p style={{ color: "#757575" }}>3. Escolha em quantas parcelas quer pagar:</p>
                    <SelectNegotiation {...propsOpcoesParcelamento()} />
                    <p className='p__negociacoesFaturas--info'>Taxa administrativa do cartão será inclusa: {results.juros.admCartao}% por parcela.</p>
                </>
            }

            {
                pagamento.forma === 'boleto' &&
                <>
                    <p style={{ color: "#757575" }}>3. Escolha o valor da entrada:</p>
                    <InputRange {...inputRangeProps} />
                    <p style={{ color: "#757575" }}>4. Escolha o intervalo dos boletos:</p>
                    <SelectNegotiation {...propsIntervaloBoletos} />
                    {
                        pagamento.boletos &&
                        <>
                            <p style={{ color: "#757575" }}>5. Escolha a quantidade de boletos: </p>
                            <SelectNegotiation {...propsOpcoesParcelamento()} />
                            <p className='p__negociacoesFaturas--info'>Taxa administrativa do boleto será inclusa: {results.juros.admBoleto}% por parcela.</p>
                        </>
                    }
                </>
            }

        </>
    )

    const disableBtnAceitarNegociacao = () => {
        const temFatura = faturas.selecionadas.length
        const forma = pagamento.forma
        const parcelas = pagamento.numParcelas
        const boletos = pagamento.boletos

        if (forma === 'pix' && temFatura) return false
        else if (forma === 'cartao' && parcelas && temFatura) return false
        else if (forma === 'boleto' && parcelas && boletos && temFatura) return false
        else return true
    }

    return (
        <>
            <NegociacoesModal data={ref.current} modals={modals} setModals={setModals} sendNegotiation={sendNegotiation} />
            <SomaStatus status={status}>
                <div className='div__negociacoesFaturas--container'>
                    <CardBox {...propsCardBox} />
                    <p className='p__negociacoesFaturas--info'>Faturas a vencer não estão incluídas.</p>

                    <p style={{ color: "#757575" }}>1.  Escolha as faturas a serem quitados:</p>
                    <TitlesSelector {...propsTitlesSelector} />

                    {faturas.totalSelecionadas?.pix?.['0'] !== 0 && formaPagamentoCode}
                </div>

                <div className='div__negociacoesFaturas--footer'>
                    <Button variant="outlined" onClick={() => { navigate('/Financeiro/faturas') }}>CANCELAR</Button>
                    <Button
                        style={{ color: 'white', fontFamily: 'flama-basic' }}
                        variant="contained"
                        disabled={disableBtnAceitarNegociacao()}
                        onClick={() => setModals(prev => ({ ...prev, confirmar: true }))}
                    >
                        ACEITAR NEGOCIAÇÃO
                    </Button>
                </div>
            </SomaStatus>
        </>
    )
}

export default NegociacoesFaturas